'use client';

import { useState, useId, useEffect } from 'react';

import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { FocusOn } from 'react-focus-on';
import { PlacesType, Tooltip } from 'react-tooltip';
import { usePathname } from 'next/navigation';
import { LanguageIcon } from '@heroicons/react/24/solid';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useUser } from '@clerk/clerk-react';

import { useTranslations } from '@/context/TranslationsContext';
import { locales as _locales, Locale, LocaleKey, Language } from '@/constants/locales';
import { getLocaleSpecificUrl } from '@/utils/locale';
import { Spinner } from '@/components/widgets/spinners/Spinner';
import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';
import { GhostButton } from '@/components/Fields/Buttons';
import { Caret } from '@/components/icons/icons';
import useMediaQuery from '@/hooks/useMediaQuery';
import tailwindConfig from '@/tailwind.config';

type LanguageSelectorProps = {
  localeKey?: LocaleKey;
  localesList?: Locale[];
  className?: string;
  customText?: string;
  redirectOnClick?: boolean;
  updateUserLocale?: boolean;
  place?: PlacesType;
  onLanguageChange?: (locale: LocaleKey) => void;
  useLanguageContext?: boolean;
  disabled?: boolean;
  loading?: boolean;
  blocking?: boolean;
  allowMultipleSelection?: boolean;
  anchor?: string;
  open?: boolean;
  children?: React.ReactNode;
  prepend?: React.ReactNode;
};

export const AlternativeLanguageSelector = (props: LanguageSelectorProps) => {
  // const { languages } = useLanguages();
  return (
    <LanguageSelector
      {...props}
      useLanguageContext
      place="bottom-end"
      // className={classNames(props.className, { 'pointer-events-none touch-none opacity-50': languages.length >= 4 })}
    >
      <span className="flex items-center gap-1">
        <LanguageIcon className="h-4 w-4" />
        Translations
      </span>
    </LanguageSelector>
  );
};

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const { localeKey, className, customText, place, children, disabled } = props;
  const { onLanguageChange, useLanguageContext, allowMultipleSelection, loading, blocking, anchor } = props;
  const { redirectOnClick, updateUserLocale, localesList, prepend } = props;
  const locales = localesList || _locales;

  const { translations, setTranslations } = useTranslations();
  const [selected, setSelected] = useState<Locale | undefined>(locales.find(({ id }) => id === localeKey));
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const pathname = usePathname() as string;
  const randomId = useId();
  const user = useUser();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const smallMediaQuery = useMediaQuery((tailwindConfig.theme?.extend?.screens as any)?.['lt-sm']?.raw);

  useEffect(() => setSelected(locales.find(({ id }) => id === localeKey)), [localeKey, locales]);

  useEffect(() => {
    if (blocking && !selected && translations.length > 0) {
      setSelected(locales.find(({ id }) => translations.includes(id)));
    }
  }, [blocking, translations, selected, locales]);

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  const selectLocaleHandler = (localeId: string) => {
    const locale = locales.find(({ id }) => id === localeId) as Locale;
    selected && setSelected(locale);
    const isAlreadySelected = !useLanguageContext ? locale === selected : translations.includes(locale.id);
    if (!isAlreadySelected) {
      onLanguageChange?.(locale.id);
      if (updateUserLocale && user) {
        void user.user?.update({ unsafeMetadata: { preferred_locale: locale.id } });
      }
    }

    if (useLanguageContext) {
      setTranslations((langs: Language[]) => {
        const newLanguages = isAlreadySelected
          ? langs.filter((currentLang) => currentLang !== locale.id) // remove selected
          : !selected || (blocking && langs.length === 1) // "blocking" is a hack for Polyglot Preview
            ? [...langs, locale.id] // add new
            : langs.map((currentLang) => (currentLang === selected.id ? locale.id : currentLang)); // replace existing
        return newLanguages;
      });
    }

    if (!allowMultipleSelection) {
      setIsOpen(false);
      setTimeout(() => setIsOpen(undefined), 100);
    }
  };

  return (
    <>
      {!anchor && (
        <div className={classNames('language-selector', 'group relative inline-flex flex-col', className)}>
          <button
            id="language-selector"
            data-dropdown-toggle="dropdown-languages"
            className={classNames(
              'flex flex-shrink-0 select-none flex-wrap items-center justify-center rounded-lg px-3 py-2 text-sm font-semibold leading-6',
              'sm:z-[102]',
              'whitespace-nowrap',
              {
                'hover:bg-primary-500/5 dark:hover:bg-white/5': !disabled,
                'sm:bg-primary-500/5 dark:sm:bg-white/5': isOpen,
                'cursor-default': disabled || loading,
              },
            )}
            type="button"
            // aria-expanded={isOpened}
            aria-controls="dropdown-languages"
            aria-label={selected?.title}
            lang={selected?.id}
            data-tooltip-id={`language-selector-tooltip-${randomId}`}
            disabled={disabled || loading}
            onClick={() => setIsOpen((prev) => !prev)}
            suppressHydrationWarning
          >
            {loading && <Spinner className="mr-2 h-3" aria-hidden="true" />}
            {allowMultipleSelection &&
              useLanguageContext &&
              locales.map(
                (locale) =>
                  translations.includes(locale.id) && (
                    <CountryFlag key={locale.id} code={locale.country} className="mr-1 h-3" aria-hidden="true" />
                  ),
              )}
            {/* {blocking && !allowMultipleSelection && !selected && (
              <LanguageIcon className="mr-0.5 h-5 w-5 stroke-current stroke-[0.6px]" />
            )} */}
            {selected && !allowMultipleSelection && (
              <div className="flex h-6 items-center gap-0.5 sm:gap-2">
                <CountryFlag code={selected.country} className="mr-0.5 h-3" aria-hidden="true" />
                {translations.length <= 2 && <span>{selected.actualTitle}</span>}
              </div>
            )}
            {customText && <span className="ml-1 whitespace-nowrap normal-case opacity-50"> ⇢ {customText}</span>}
            {children}
            {!disabled && !loading && (
              <Caret className="ml-1.5 h-2.5 w-2.5 opacity-50 transition-opacity group-hover:opacity-100" />
            )}
          </button>
        </div>
      )}
      {/* onEscapeKey={() => setIsOpened(false)} onClickOutside={() => setIsOpened(false)} */}
      {typeof window !== 'undefined' &&
        createPortal(
          <>
            {isOpen && (
              <div
                onClick={() => setIsOpen(false)}
                className="fixed inset-0 z-[101] h-full min-h-screen w-full sm:backdrop-blur-sm lt-sm:backdrop-blur-xl"
              ></div>
            )}
            <Tooltip
              id={`language-selector-tooltip-${randomId}`}
              className={classNames(
                '!rounded-lg !p-0',
                'lt-sm:!fixed lt-sm:!left-0 lt-sm:!w-full',
                '!bg-white dark:!bg-gray-800',
                'lt-sm:!top-0 lt-sm:!bg-transparent lt-sm:!pt-[5rem] lt-sm:dark:lt-sm:!bg-transparent',
                '[&_.react-tooltip-arrow]:z-[-1]',
                {
                  'dark:lt-sm:!bg-transparent': blocking,
                  'z-[60]': !isOpen,
                  'z-[102]': isOpen,
                  '!opacity-100': isOpen,
                },
              )}
              openOnClick
              clickable
              globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
              opacity={1}
              place={place}
              imperativeModeOnly={disabled}
              isOpen={isOpen}
              anchorSelect={anchor}
            >
              <FocusOn enabled={smallMediaQuery}>
                <div
                  id="dropdown-languages"
                  className="flex flex-col gap-5 overflow-auto lt-sm:!h-[calc(100dvh-6rem)] lt-sm:h-[calc(100vh-4rem)]"
                >
                  <GhostButton
                    className="fixed top-2 mx-4 flex min-h-[4rem] w-[calc(100%-2rem)] items-center justify-center text-black dark:text-white sm:hidden"
                    onClick={() => setIsOpen(false)}
                  >
                    <ArrowUturnLeftIcon className="h-4 w-4" />
                    Close
                  </GhostButton>
                  <ul
                    className={classNames(
                      'text-sm text-gray-700 dark:text-gray-200',
                      // 'columns-3 sm:columns-4 md:columns-5 lg:columns-6 lt-tiny:columns-2',
                      'grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))]',
                      'sm:py-2 lt-sm:px-4',
                      'sm:max-h-[calc(100vh/2)] sm:min-w-[calc(100vw/2)] sm:overflow-y-scroll',
                      'select-none',
                    )}
                    role="listbox"
                  >
                    {locales.map((locale) => (
                      <li key={locale.id} className="m-[0.5px]">
                        <a
                          type="button"
                          className={classNames(
                            'inline-flex w-full cursor-pointer text-sm sm:px-2',
                            'text-gray-700 dark:text-gray-400 dark:hover:text-white',
                            {
                              'bg-gray-100 dark:bg-gray-600':
                                (useLanguageContext && translations.includes(locale.id)) || locale.id === selected?.id,
                            },
                          )}
                          href={redirectOnClick ? getLocaleSpecificUrl(locale.id, pathname) : undefined}
                          onClick={() => selectLocaleHandler(locale.id)}
                          lang={locale.id}
                          aria-selected={locale.id === selected?.id}
                          role="option"
                        >
                          <div className="inline-flex items-center gap-2 px-2 py-2">
                            <CountryFlag
                              code={locale.country}
                              className="!h-6 !w-8 shrink-0 grow-0"
                              aria-hidden="true"
                            />
                            <div className="flex flex-col items-start text-left">
                              {locale.actualTitle}
                              <div className="text-xs opacity-60">{locale.title}</div>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                  {prepend}
                </div>
              </FocusOn>
            </Tooltip>
          </>,
          document.body,
        )}
    </>
  );
};
